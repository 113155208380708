/*global $*/
/*eslint no-undef: "error"*/
export default class ProductView {
    classBlock = 'product-view--block'
    classSection = 'product-view--section'
    classButton = 'product-view--button'

    classBlockActive = 'product-view-active'
    classSectionActive = 'product-bottom__block-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).closest(`.${self.classBlock}`).data('block')

            $(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classBlock}`).addClass(self.classBlockActive)

            $(`.${self.classSection}`).removeClass(self.classSectionActive)
            $(`.${self.classSection}[data-block='${block}']`).addClass(self.classSectionActive)
        })
    }
}
