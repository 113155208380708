/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonForm {
    classItem = 'button-form--item'
    classButton = 'button-form--button'
    classButtonForm = 'button-form'

    classItemOpen = 'open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classItem}`).toggleClass(self.classItemOpen)
            $(`.${self.classButtonForm}`).toggleClass(self.classItemOpen)
        })
    }
}
