/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import tippy from 'tippy.js'
import Inputmask from 'inputmask'
import { Fancybox } from '@fancyapps/ui'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormMain from '@/components/Form/AppFormMain.vue'
import AppFormLine from '@/components/Form/AppFormLine.vue'
import AppFormMaterial from '@/components/Form/AppFormMaterial.vue'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import PhonePopup from '@/assets/js/Phone/Popup/PhonePopup'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import MenuCatalog from '@/assets/js/Menu/Catalog/MenuCatalog'
import HeaderCatalog from '@/assets/js/Header/Catalog/HeaderCatalog'
import ButtonFaq from '@/assets/js/Button/Faq/ButtonFaq'
import ButtonCount from '@/assets/js/Button/Count/ButtonCount'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import ButtonForm from '@/assets/js/Button/Form/ButtonForm'
import ProductView from '@/assets/js/Product/View/ProductView'
import Tab from '@/assets/js/Tab/Tab/Tab'
import TabLine from '@/assets/js/Tab/Line/TabLine'
import TabTable from '@/assets/js/Tab/Table/TabTable'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import InputPayment from '@/assets/js/Input/InputPayment/InputPayment'
import Filter from '@/assets/js/Filter/Filter'
import OrderButton from '@/assets/js/Order/Button/OrderButton'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    tippy('.button-icon-content-bottom', {
        theme: 'light',
        arrow: true,
        maxWidth: 1000,
        placement: 'bottom-start',
        animation: 'scale-extreme',
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true
    })

    Inputmask('7 (999) 999-9999').mask('.input-text--phone')

    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    if(document.getElementById('block--form-sub') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-sub')
    }

    if(document.getElementById('block--form-phone') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone')
    }

    if(document.getElementById('block--form-order') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-order')
    }

    if(document.getElementById('block--form-product') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-product')
    }

    if(document.getElementById('block--form-online') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-online')
    }

    if(document.getElementById('block--form-main') != null) {
        createApp({})
            .component('block-form-main', AppFormMain)
            .use(Maska)
            .use(form)
            .mount('#block--form-main')
    }

    if(document.getElementById('block--form-line') != null) {
        createApp({})
            .component('block-form-line', AppFormLine)
            .use(Maska)
            .use(form)
            .mount('#block--form-line')
    }

    if(document.getElementById('block--form-material') != null) {
        createApp({})
            .component('block-form-material', AppFormMaterial)
            .use(Maska)
            .use(form)
            .mount('#block--form-material')
    }

    new SwiperSlider()
    new PhonePopup()
    new HeaderMobile()
    new MenuCatalog()
    new HeaderCatalog()
    new ButtonFaq()
    new ButtonCount()
    new ButtonTop()
    new ButtonForm()
    new ProductView()
    new Tab()
    new TabLine()
    new TabTable()
    new MapIframe()
    new InputPayment()
    new Filter()
    new OrderButton()
})
